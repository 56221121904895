.sort-and-search-bar {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  z-index: 800;
  background-color: #111111;
  margin-top: -7px;
  padding-top: 4px;
  padding-bottom: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Increased box shadow */
   
}

.dropdown-container {
  width: 32.5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Increased box shadow */
  border-radius: 6px; 
}
.search-container{
  width: 32.5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Increased box shadow */
  border-radius: 6px; 
}

@media (max-width: 806px) {
  .sort-and-search-bar {
    flex-direction: column;
    height: auto;
  }
  .dropdown-container{
    width: 80%;
    margin-left: 10%;
    margin-bottom: 6px;
  }
  .search-container {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 18px;
  }
}
